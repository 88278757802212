import { globalHistory, navigate } from "@reach/router";
import { Link } from "gatsby";
import React, { useEffect, useState } from "react";
import { Trans } from "react-i18next";
import VacancyAndThanks from "../../../../components/CombineModals/VacancyAndThanks";
import Layout from "../../../../components/Layout";
import SEO from "../../../../components/Seo";
import ContactsBox from "../../../../components/VacanciesPageComponents/Contacts-box/ContactsBox";
import VacanciesShortList from "../../../../components/VacanciesPageComponents/VacanciesShortList";
import VacancyLinkBack from "../../../../components/VacanciesPageComponents/VacancyLinkBack";
import { createMarkup } from "../../../../helpers/createMarkup";
import FacebookIcon from "../../../../images/vacancy/fb.inline.svg";
import LinkedInIcon from "../../../../images/vacancy/linkedin.inline.svg";

const VacancyPosition = ({ pageContext: { data, common_data } }) => {
  const queryVacancyId = globalHistory.location.pathname.split("/")[4];
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [vacancy, setVacancy] = useState(null);

  const langFromQuery = globalHistory?.location.pathname.split('/')[1];

  const openModalHandler = () => {
    setIsOpenModal(true);
  };
  const closeModalHandler = () => {
    setIsOpenModal(false);
  };

  useEffect(() => {
    if(typeof vacancy === 'undefined' ){
      navigate(`/${langFromQuery}/vacancies/not-found/`);
    }
  }, [vacancy]);

  useEffect(() => {
    setVacancy(data.vacancies_list?.find(
      ({ vacancy_id }) => vacancy_id.id === +queryVacancyId
    ))
  }, []);
  
  return (
    <Layout>
      <SEO title="Vacancy positon" />
      <VacancyLinkBack vacancy={vacancy} />
      <section className="d-lg-flex flex-column mb-7 d-md-block vacancy-position-container">
        <div className="container d-block d-lg-flex d-md-block d-sm-block ">
          <div className="vacancy-position-in">
            <div className="text-left">
              {vacancy?.isHotVacancy && (
                <div className="d-inline-flex align-items-center vacation-title">
                  <div className="vacation-icon">
                    <span role="img" aria-label="vacation-icon">
                      🔥
                    </span>
                  </div>
                  <span className="vacation-title-text font-size-lg text-gray-light font-weight-medium">
                    <Trans i18nKey="homePage.vacation.name" />
                  </span>
                </div>
              )}
              <h1>{vacancy?.title}</h1>
              <div className="vacancy-position-subtitle">
                {vacancy?.address} &nbsp; {vacancy?.experience}
              </div>
              <button
                onClick={openModalHandler}
                className="btn btn-primary rounded-pill vacancy-top-btn"
              >
                <Trans i18nKey="homePage.vacation.contactUs" />
              </button>
            </div>
            <div
              className="vacancy-text"
              dangerouslySetInnerHTML={createMarkup(vacancy?.description)}
            />
            <br />
            <div className="mt-30 d-flex flex-column flex-md-row align-items-center justify-content-center justify-content-md-between">
              <div className="mb-4 mb-md-0">
                <button
                  onClick={openModalHandler}
                  className="btn btn-primary btn-hg rounded-pill vacancy-bottom-btn"
                >
                  <Trans i18nKey="homePage.vacation.contactUs" />
                </button>
              </div>
              <div className="d-flex flex-column flex-md-row flex-sm-row  align-items-center justify-content-center justify-content-md-between ml-sm-0 ml-md-5">
                <div className="text-gray-light pb-3 pb-lg-0 pb-md-0 text-contacts text-center">
                  <Trans i18nKey="homePage.vacation.socials" />
                </div>
                <div className="pl-md-2 d-flex">
                  <Link
                    to={common_data?.facebook_link}
                    target="_blank"
                    className="ml-20 mr-md-0"
                    rel="noreferrer"
                  >
                    <FacebookIcon alt="Facebook's icon" />
                  </Link>
                  <Link
                    to={common_data?.linkedin_link}
                    target="_blank"
                    className="ml-15 mr-md-0"
                    rel="noreferrer"
                  >
                    <LinkedInIcon alt="LinkedIn's icon" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex flex-column ">
            <div className="stickyContainer">
              <ContactsBox
                title={data.contacts_title}
                contact={common_data.contacts}
              />
              <VacanciesShortList
                title={data.popular_vacancies_title}
                vacanciesList={data.vacancies_list}
              />
            </div>
          </div>
        </div>
      </section>

      <VacancyAndThanks
        show={isOpenModal}
        onHide={closeModalHandler}
        title={vacancy?.title}
      />
    </Layout>
  );
};

export default VacancyPosition;
