import React, { useState, useEffect } from 'react';
import Fire from "../../../images/contacts/fire.inline.svg";
import { Link } from "gatsby";
import { globalHistory } from '@reach/router';

const VacanciesShortList = ({title, vacanciesList}) => {
  const [lang, setLange] = useState('');

  useEffect(() => {
    setLange(globalHistory.location.pathname.split('/')[1]);
  }, []);
  return (
    <div className='vacancy-position'>
      <h3>{title}</h3>
      <ul>
        { vacanciesList.map(vacancy=>{
          const idVacancy = vacancy?.vacancy_id.id;
          return(
               <li className={vacancy.isHotVacancy ? "hotVacancy" : ""} key={vacancy.id}>
                  <Fire alt='contact image the email' className={ !vacancy.isHotVacancy ? "notHotVacancy" : "" }/>
                  {/* <Link to={`/ru/vacancies/vacancy/${idVacancy}`}><p>{vacancy.title}</p></Link> */}
                  <a href={`/${lang}/vacancies/vacancy/${idVacancy}`}>{vacancy.title}</a>
              </li>
             )
          })
        }
      </ul>
    </div>
  )
}

export default VacanciesShortList;
