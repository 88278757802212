import {Link} from "gatsby";
import {Trans} from "react-i18next";
import React, {useEffect, useState} from "react";
import {globalHistory} from "@reach/router";

const VacancyLinkBack =( {vacancy}) =>{
  const [lang, setLange] = useState('');
  useEffect(() => {
    setLange(globalHistory.location.pathname.split('/')[1])
  }, []);

  return(
  <div className="d-flex flex-column mt-5 mobile">
    <div className="container d-flex ">
      <div >
        <Link to={`/${lang}/vacancy`} className="vacancy-back">
          <svg
            width="27"
            height="12"
            viewBox="0 0 27 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={"pictureLinkBack"}
          >
            <path d="M26.495 6.49497C26.7683 6.22161 26.7683 5.77839 26.495 5.50503L22.0402 1.05025C21.7668 0.776886 21.3236 0.776886 21.0503 1.05025C20.7769 1.32362 20.7769 1.76684 21.0503 2.0402L25.01 6L21.0503 9.9598C20.7769 10.2332 20.7769 10.6764 21.0503 10.9497C21.3236 11.2231 21.7668 11.2231 22.0402 10.9497L26.495 6.49497ZM0 6.7L26 6.7L26 5.3L0 5.3L0 6.7Z" />
          </svg>
          <span className="vacation-title-text font-size-lg font-weight-medium"><Trans
            i18nKey="homePage.vacation.name"/> {`> ${vacancy?.title}`} </span>
        </Link>
      </div>
    </div>
  </div>
 )
}

export default VacancyLinkBack;
