import React, { useEffect, useState } from 'react';
import VacancyForm from '../Forms/VacancyForm/VacancyForm';
import { Modal } from '../ModalForm';
import { Thanks } from '../Thanks';

const VacancyAndThanks = ({ show = false, onHide = () => {}, title = '' }) => {
  const [isVacancyModalShow, setIsVacancyModalShow] = useState(false);
  const [isThanksModalShow, setIsThanksModalShow] = useState(false);

  useEffect(() => {
    if (show) setIsVacancyModalShow(true);
  }, [show]);

  const hideVacancyModal = () => {
    setIsVacancyModalShow(false);
    onHide();
  };
  const openThanksModal = () => {
    setIsThanksModalShow(true);
  };

  const hideThanksModal = () => {
    setIsThanksModalShow(false);
  };
  const onSubmitHendler = () => {
    hideVacancyModal();
    openThanksModal();
  };
  return (
    <>
      <Modal
        show={isVacancyModalShow}
        onHide={hideVacancyModal}
        children={
          <VacancyForm
            onHide={hideVacancyModal}
            onSubmit={onSubmitHendler}
            title={title}
          />
        }
      />
      <Modal
        show={isThanksModalShow}
        onHide={hideThanksModal}
        children={<Thanks hideThanksModal={hideThanksModal}/>}
      />
    </>
  );
};
export default VacancyAndThanks;
