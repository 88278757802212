import React, { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import {
  isRelevanceEmail,
  isRequired,
  maxSizeFile,
} from '../../../constants/validationFunction';
import useForm from '../../../hooks/useForm';
import FileImg from '../../../images/file.inline.svg';

const VacancyForm = ({ onHide, onSubmit, title }) => {
  const [isSelectFile, setIsSelectFile] = useState(false);
  const [isErrorFile, setIsErrorFile] = useState(false);
  const classForFileBox = isErrorFile ? 'error-box' : 'success-box';

  const { t } = useTranslation();

  const emptyField = t('vacancyModal.err.empty');
  const invalidEmailField = t('vacancyModal.err.errEmail');
  const invalidSizeFileField = t('vacancyModal.err.invalidSizeFile');

  const hiddenFileInput = React.useRef(null);

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const onSubmitHandler = (formData = {}) => {
    onHide();
    onSubmit(formData);
    resetFormState();
  };
  const initialForm = [
    {
      label: `${t('vacancyModal.name')}*`,
      name: 'userName',
      validators: [{ func: isRequired, message: emptyField }],
    },
    {
      label: `${t('vacancyModal.email')}*`,
      name: 'email',
      extraParams: { type: 'email' },
      validators: [
        { func: isRequired, message: emptyField },
        { func: isRelevanceEmail, message: invalidEmailField },
      ],
    },
    {
      label: `${t('vacancyModal.text')}*`,
      name: 'textareaUser',
      extraParams: { as: 'textarea', rows: 3 },
      validators: [{ func: isRequired, message: emptyField }],
    },
    {
      name: 'file',
      extraParams: {
        type: 'file',
        ref: hiddenFileInput,
        style: { display: 'none' },
      },
      validators: [
        { func: isRequired, message: emptyField },
        {
          func: maxSizeFile,
          message: invalidSizeFileField,
          extraParams: { max: 10000000 },
        },
      ],
    },
    ,
  ];
  const {
    data,
    errors,
    onChange,
    isValid,
    isInvalid,
    handleSubmit,
    resetFormState,
  } = useForm(onSubmitHandler, initialForm);

  const fileColor = () => {
    if (errors.file) return '#EB5757';
    if (isSelectFile && !errors.file) return '#03A473';
    return '#363636';
  };

  const deteleSelectFileHandler = (e) => {
    e.stopPropagation();
    onChange({ target: { name: 'file', value: '', type: 'text' } });
  };

  useEffect(() => {
    setIsSelectFile(!!('file' in data));
    setIsErrorFile(!!('file' in errors));
  }, [data, errors]);

  return (
    <>
      <p className="vacancy-title">{t('vacancyModal.vacancy')}</p>
      <p className="vacancy">{title}</p>
      <Form className="form-default" onSubmit={handleSubmit}>
        {initialForm.map((fieldGroup) => (
          <Form.Group key={fieldGroup.label || fieldGroup.name}>
            {fieldGroup.label && (
              <Form.Label
                className={
                  errors[fieldGroup.name]
                    ? 'error-msg'
                    : `${
                        data[fieldGroup.name]?.length
                          ? 'text-gray-light'
                          : 'text-dark'
                      }`
                }
              >
                {fieldGroup.label}
              </Form.Label>
            )}
            <Form.Control
              name={fieldGroup.name}
              isValid={isValid(fieldGroup.name)}
              isInvalid={isInvalid(fieldGroup.name)}
              onChange={onChange}
              className={errors[fieldGroup.name] ? 'error-input' : ''}
              {...fieldGroup.extraParams}
            />
            {fieldGroup.label && errors[fieldGroup.name] && (
              <Form.Label className="error-msg">
                {errors[fieldGroup.name]}
              </Form.Label>
            )}
          </Form.Group>
        ))}
        <Form.Group className="file-group">
          <button
            className="selectFile-btn"
            onClick={handleClick}
            type="button"
          >
            <div
              className={`file-wrapper ${
                isErrorFile || isSelectFile ? classForFileBox : ''
              }`}
            >
              <div className="image-box">
                <div className="image-box-circle">
                  {isSelectFile && (
                    <button onClick={deteleSelectFileHandler} type="button">
                      &times;
                    </button>
                  )}
                  <FileImg
                    className="file-img"
                    width={14}
                    height={25}
                    fill={fileColor()}
                  />
                </div>
              </div>
              <div className="inform-box">
                {isSelectFile ? (
                  data?.file?.name && (
                    <span className="fileTitle">{data.file.name}</span>
                  )
                ) : (
                  <>
                    <span className="fileTitle">
                      {t('vacancyModal.attach')}
                    </span>
                    <span>{t('vacancyModal.size')}</span>
                  </>
                )}
              </div>
            </div>
          </button>
          {errors['file'] && (
            <span className="error-msg">{errors['file']}</span>
          )}
        </Form.Group>
        <Form.Group className="form-group-btn">
          <Button
            variant="primary"
            type="submit"
            className="btn btn-lg btn-primary rounded-pill vacancy-submitt-btn"
          >
            {t('vacancyModal.submit')}
          </Button>
          <button className={'btn cansel'} onClick={onHide}>
            {t('vacancyModal.cancel')}
          </button>
        </Form.Group>
      </Form>
    </>
  );
};

export default VacancyForm;
