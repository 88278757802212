import React from "react";
import Email from "../../../images/contacts/email.inline.svg";
import Phone from "../../../images/contacts/phone.inline.svg";

const ContactsBox = ({title, contact}) => {
  return (
    <div className='vacancy-position'>
      <h3>{title}</h3>
      <ul>
        <li>
          <Email alt='contact image the email'/><p>{contact.email}</p>
        </li>
        <li>
          <Phone alt='contact image the first phone'/><p>{contact.first_phone_number}</p>
        </li>
        <li>
          <Phone alt='contact image the second phone'/><p>{contact.second_phone_number}</p>
        </li>
      </ul>
    </div>
  )
}
export default ContactsBox;
